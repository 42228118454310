define('ember-validations/errors', ['exports', 'ember'], function (exports, _ember) {
  var EmberObject =
  //   get,
  //   set
  _ember['default'].Object;
  exports['default'] = EmberObject.extend({
    //   unknownProperty(property) {
    //     set(this, property, emberArray());
    //     return get(this, property);
    //   }
  });
});

//   A: emberArray,